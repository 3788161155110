<template>
  <div>
    <div class="searchBox">
      <div class="searchLeft">
        <!-- <div>
          <el-select
            size="medium"
            v-model="searchForm.id"
            disabled
            placeholder="国家组用途"
            @change="getTableList"
          >
            <el-option
              v-for="(item) in useOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </div> -->
        <!-- <el-button size="medium" type="primary" icon="el-icon-search" @click="getTableList(true)">搜索</el-button> -->
      </div>
      <div class="searchRight">
        <el-button
          size="medium"
          type="warning"
          icon="el-icon-plus"
          @click="addFunc"
          >创建</el-button
        >
      </div>
    </div>

    <el-table border :data="tableData" height="65vh" style="width: 99%">
      <el-table-column prop="name" label="国家组">
      </el-table-column>
      <el-table-column prop="inventory" label="目标国家组">
        <template slot-scope="scope">
          <span>{{ scope.row.configList.map(item=>item.name).join(',') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="toEdit(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="small"
            type="danger" 
            @click="delFunc(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
    <el-dialog  :close-on-click-modal="false" :title="`${ruleForm.id?'编辑':'添加'}`" :visible.sync="addVisible" width="700px" append-to-body>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="130px"
        class="demo-ruleForm"
      >
      <!-- <el-form-item label="国家组用途选择" prop="parentId" :rules="rules.changeRule">
          <el-select
            v-model="ruleForm.parentId"
            placeholder="请选择"
            @change="countryGroupUseChange"
          >
            <el-option
              v-for="(item) in useOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="国家组选择" prop="id" :rules="rules.changeRule">
          <el-select
            v-model="ruleForm.id"
            placeholder="请选择"
          >
            <el-option
              v-for="(item) in countryOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="国家组设置" prop="targetId" :rules="rules.changeRule">
          <el-select v-model="ruleForm.targetId" filterable multiple placeholder="请选择">
              <el-option
                v-for="item in countryOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
      </el-form>
      
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFunc">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pagination from '@/components/pagination'
export default {
  data() {
    return {
      totalNum: 0,
      addVisible: false,
      ruleForm: {},
      searchForm: {
        pageNo: 1,
        pageSize: 10
      },
      tableData: [],
      useOptions:[],
      countryOptions:[],
      countryGroupList:[],
      useList:[],
      rules: {
        blurRule: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        changeRule: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        typeIcon: [{ required: true, message: "请上传图片", trigger: "change" }],
      },
    };
  },
  components:{pagination},
  props:['id'],
  created(){
    this.searchForm.id = this.id;
    this.getTableList();
    this.getCountryGroupList();
  },
  methods: {
    addFunc(){
      this.ruleForm = {}
      this.ruleForm.parentId = this.searchForm.id
      this.countryGroupUseChange()
      this.addVisible = true;
    },
    toEdit(data) {
      this.ruleForm = {...data};
      this.ruleForm.targetId = data.configList.map(x=>x.id)
      this.countryOptions = this.useOptions.filter(x=>x.id==data.parentId)[0].configList
      this.addVisible = true;
    },
    countryGroupUseChange() {
      this.countryOptions = this.useOptions.filter(x=>x.id==this.ruleForm.parentId)[0].configList
    },
    async submitFunc() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.ruleForm.initInventory = this.ruleForm.inventory
          let res = await this.$http.countryGroupConfigSaveGroupLook({...this.ruleForm});
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
            this.addVisible = false;
            this.$refs.ruleForm.resetFields();
          }
        }
      });
    },
    async getCountryGroupList() {
      let res = await this.$http.countryGroupConfigList();
      if (res) {
        this.useOptions = res.data;
      }
    },
    async getTableList() {
      let res = await this.$http.countryGroupConfigGroupLookList({...this.searchForm});
      if (res) {
        this.tableData = res.data;
      }
    },
    delFunc(row) {
      this.$confirm(`确定要删除么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.countryGroupConfigDelLook({id:row.id})
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.groupItem{
  display: flex;
  gap: 10px;
}
</style>
<style>
.inner-dialog {
    z-index: 2100 !important; 
}
</style>
