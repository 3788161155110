<template>
    <div class="pageBox" v-if="totalNum > 0">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 100, 200]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalNum"
      >
      </el-pagination>
    </div>
</template>
<script>
export default {
  props:{
    totalNum: {
      type: Number,
      required: true,
      default: 0
    },
    currentPage: {
      type: Number,
      required: true
    },
    pageSize: {
      type: Number,
      required: true
    },
  },
  methods: {
    handleSizeChange(val) {
      this.$emit('update:pageSize', val);
      this.$emit('update:currentPage', 1); 
      this.$emit('paginationChange');
      this.scrollToTop();
    },
    handleCurrentChange(val) {
      this.$emit('update:currentPage', val);
      this.$emit('paginationChange');
      this.scrollToTop();
    },
    scrollToTop(){
      document.querySelector('.el-table--scrollable-y .el-table__body-wrapper').scrollTop = 0;
    }
  },
};
</script>