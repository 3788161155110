<template>
    <el-dialog  :close-on-click-modal="false" :title="`${ruleForm.id?'编辑':'添加'}支线任务`" :visible.sync="addVisible" width="850px" top="3vh">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="任务标题" prop="branchTitle" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.branchTitle"
          ></el-input>
        </el-form-item>
        <el-form-item label="任务描述" prop="branchDescription" :rules="rules.blurRule">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="请输入内容"
            v-model="ruleForm.branchDescription">
          </el-input>
        </el-form-item>
        <el-form-item label="任务图标" prop="icon" :rules="rules.typeIcon">
          <uploadImg @change="uploadImgChange" :imageFile="[{url:ruleForm.icon}]"></uploadImg>
        </el-form-item>
        <el-form-item label="所属类型" prop="validType" :rules="rules.changeRule">
          <el-select
            clearable
            v-model="ruleForm.validType"
            placeholder="类型"
          >
            <el-option
              v-for="(item,index) in taskTypesOptions"
              :key="index"
              :label="item.text"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="[0,1].includes(Number(ruleForm.validType))?'分享链接':'跳转链接'" v-if="[0,1,2,4,5,11,12].includes(Number(ruleForm.validType))" prop="taskContent.jumpUrl" :rules="rules.blurRule">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="ruleForm.taskContent.jumpUrl">
          </el-input>
        </el-form-item>
        <el-form-item label="跳转类型" v-if="[2,4,5,11,12].includes(Number(ruleForm.validType))" prop="taskContent.jumpType" :rules="rules.blurRule">
          <el-radio-group v-model="ruleForm.taskContent.jumpType">
            <el-radio :label="0">内部跳转</el-radio>
            <el-radio :label="1">外部跳转</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="ruleForm.validType==7">
          <el-form-item label="主币">
            <el-checkbox v-model="ruleForm.taskContent.mainToken">是否是主币</el-checkbox>
          </el-form-item>
          <el-form-item label="合约地址" prop="taskContent.contractAddress" :rules="rules.blurRule">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="ruleForm.taskContent.contractAddress">
            </el-input>
          </el-form-item>
          <el-form-item label="链Id" prop="taskContent.chainId" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.taskContent.chainId"
            ></el-input>
          </el-form-item>
        </template>
        <el-form-item :label="[9,13].includes(Number(ruleForm.validType))?'时长':'个数'" prop="taskContent.targetNum" :rules="rules.blurRule" v-if="[9,10,11,13].includes(Number(ruleForm.validType))">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.taskContent.targetNum"
          >
          <template slot="append" v-if="[9,13].includes(Number(ruleForm.validType))">分钟</template>
          <template slot="append" v-else>个</template>
          </el-input>
        </el-form-item>
        <!-- <el-form-item label="结果校对" prop="taskDescription" :rules="rules.blurRule">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="请输入内容"
            v-model="ruleForm.taskDescription">
          </el-input>
        </el-form-item> -->
        <div v-for="(item,index) in ruleForm.reward " :key="index" class="rewardBox">
          <el-form-item :label="'任务奖励0'+(index+1)" :prop="'reward.' + index + '.rewardName'" :rules="rules.changeRule">
            <el-select v-model="item.rewardName" placeholder="请选择" style="width:150px !important">
              <el-option label="talk" value="talk"></el-option>
              <el-option label="afa" value="afa"></el-option>
              <el-option label="道具" value="avatar_props"></el-option>
              <el-option label="转盘" value="roulette"></el-option>
              <el-option label="nft" value="nft"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" label-width="0" :prop="'reward.' + index + '.amount'" :rules="rules.blurRule">
            <el-input
              placeholder="输入数量"
              v-model="item.amount"
              style="width:150px !important"
            >
            <template slot="append">
              <span v-if="item.rewardName=='avatar_props'">天</span>
              <span v-else>个</span>
            </template>
          </el-input>
          </el-form-item>
          <el-form-item label="" label-width="0" :prop="'reward.' + index + '.type'" :rules="rules.blurRule" v-if="item.rewardName=='nft'">
            <el-select
              clearable
              v-model="item.type"
              placeholder="类型"
              style="width:150px !important"
            >
              <el-option label="链上" :value="1"></el-option>
              <el-option label="链下" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" label-width="0" :prop="'reward.' + index + '.icon'" :rules="rules.changeRule" v-if="item.rewardName=='nft'||item.rewardName=='roulette'">
            <uploadImg @change="val=>uploadImgChange2(index,val)" :imageFile="[{url:item.icon}]"></uploadImg>
          </el-form-item>
          <div class="flex" v-if="item.rewardName=='avatar_props'">
              <el-form-item  label="" label-width="0" :prop="'reward.' + index + '.propsReward'" :rules="rules.changeRule" v-if="item.rewardName=='avatar_props'">
                <el-select v-model="item.propsReward" placeholder="选择道具">
                  <el-option
                    v-for="(item,index) in propsList"
                    :key="index"
                    :label="item.name"
                    :value="JSON.stringify(item)">
                    <span style="float: left">
                      <img :src="item.icon" alt="" style="width: 40px;height: 40px;">
                    </span>
                    <p style="float: right; color: #8492a6; font-size: 13px">{{ item.name }} <span style="color: #8492a6; font-size: 13px">({{item.type==0?'进场特效':'头像框'}})</span></p>
                  </el-option>
                </el-select>
              </el-form-item>
              <img :src="JSON.parse(item.propsReward).icon" alt="" style="width: 40px;height: 40px;margin-bottom: 20px;" v-if="item.propsReward">
          </div>
          <el-button type="danger" icon="el-icon-delete" style="margin-bottom:20px;height:42px;" circle @click="delReward(index)" v-if="ruleForm.reward.length>1?true:index>0"></el-button>
        </div>
        <el-form-item label="">
          <el-button
            size="medium"
            type="warning"
            @click="addReward"
            >增加任务奖励</el-button
          >
          
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeFunc">取 消</el-button>
        <el-button type="primary" @click="submitFunc">确 定</el-button>
      </span>
    </el-dialog>
</template>

<script>
import uploadImg from '@/components/uploadImg'
export default {
  props: {
    parentData:{
      type: Object,
      default: () => {},
    },
    dataRow: {
      type: Object,
      default: () => {},
    },
    addVisible:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      totalNum: 0,
      ruleForm: {},
      taskTypesOptions: [{ text: '分享校验', value: 0 }, { text: '拉新校验', value: 1 }, { text: '浏览指定页面', value: 2 },
      { text: 'swap校验', value: 3 }, { text: '分享x', value: 4 }, { text: '关注x', value: 5 },
      { text: '点赞x', value: 6 }, { text: '转账校验', value: 7 }, { text: '质押校验', value: 8 },
      { text: '上麦聊天', value: 9 },{ text: '发送礼物打赏', value: 10 },{ text: '提现talk', value: 11 },{ text: '加入tg', value: 12 },{ text: '房间停留任务', value: 13 }],
      taskLabelsOptions: [],
      propsList: [],
      rules: {
        blurRule: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        changeRule: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        typeIcon: [{ required: true, message: "请上传图片", trigger: "change" }],
      },
    };
  },
  components:{uploadImg},
  watch: {
    dataRow: {
      handler(val) {
        let ruleForm = {}
        if (val.id) {
          ruleForm = { ...val };
          ruleForm.taskContent = JSON.parse(ruleForm.taskContent)
          ruleForm.reward = JSON.parse(ruleForm.reward)
          ruleForm.reward = ruleForm.reward.map(item=>{
            if(item.rewardName=='avatar_props'){
              item.propsReward = JSON.stringify(this.propsList.find(x=>x.id==item.id))
            }
            return item
          })
          if([9,13].includes(Number(ruleForm.validType))){
              ruleForm.taskContent.targetNum = ruleForm.taskContent.targetNum/60000
          }
        } else {
          ruleForm.reward=[]
          ruleForm.taskContent = {}
        }
        this.ruleForm=JSON.parse(JSON.stringify(ruleForm))
      },
      immediate: true,
    }
  },
  created() {
    this.getTagList();
    this.getPropsList()
  },
  methods: {
    async getPropsList(){
      let res = await this.$http.userPropsConfigList({pageNo:1,pageSize:1000,status:1});
      if (res) {
        this.propsList = res.result.data; 
      }
    },
    addReward(){
      this.ruleForm.reward.push({})
    },
    delReward(n){
      this.ruleForm.reward.splice(n,1)
    },
    uploadImgChange(val){
      if(val&&val.length>0) {
        this.ruleForm.icon=val[0].url
      }
    },
    uploadImgChange2(index,val){
      if(val&&val.length>0) {
        this.ruleForm.reward[index].icon=val[0].url
      }
    },
    async submitFunc() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
          if([9,13].includes(Number(ruleForm.validType))){
            ruleForm.taskContent.targetNum = ruleForm.taskContent.targetNum*60*1000
          }
          ruleForm.taskContent = JSON.stringify(ruleForm.taskContent)
          ruleForm.reward = ruleForm.reward.map(item=>{
            if(item.rewardName=='avatar_props'){
              item.id = JSON.parse(item.propsReward).id
              item.icon = JSON.parse(item.propsReward).icon
            }
            return item
          })
          ruleForm.reward = JSON.stringify(ruleForm.reward)
          ruleForm.taskId=this.parentData.id
          ruleForm.step = this.parentData.branchList?.length+1 || 1
          
          let res = await this.$http.taskBranchSave({...ruleForm});
          if (res) {
            this.$message.success("操作成功！");
            this.$emit('refresh')
            this.closeFunc();
            this.$refs.ruleForm.resetFields();
          }
        }
      });
    },
    async getTagList() {
      let res = await this.$http.taskTagList({pageNo:1,pageSize:100});
      if (res) {
        this.taskLabelsOptions = res.result.data;
      }
    },
    closeFunc(){
       this.$emit('close') 
    }
  },
};
</script>
<style lang="scss">
  .rewardBox{
    display: flex;
    gap: 10px;
    .el-input{
      width: 100% !important;
    }
  }
</style>