<template>
  <div>
    <div class="searchBox">
      <div class="searchLeft">
        <div>
          <el-input
              size="medium"
              clearable
              v-model="searchForm.name"
              placeholder="请输入礼物名称"
              @keyup.enter.native="getTableList"
          ></el-input>
        </div>
        <el-button size="medium" type="primary" icon="el-icon-search" @click="getTableList">搜索</el-button>
      </div>
      <div class="searchRight">
        <el-button
          size="medium"
          type="warning"
          icon="el-icon-plus"
          @click="addFunc"
          >新增</el-button
        >
      </div>
    </div>
    <el-table border :data="tableData" height="76vh" style="width: 99%">
      <el-table-column prop="id" label="id"></el-table-column>
      <el-table-column prop="giftName" label="礼物名称"></el-table-column>
      <el-table-column label="图标">
        <template slot-scope="scope">
          <img :width="100" :src="scope.row.giftLogo" class="icon" />
        </template>
      </el-table-column>
      <el-table-column prop="costPrice" label="花费"></el-table-column>
      <el-table-column prop="costCurrency" label="花费币种"></el-table-column>
      <el-table-column prop="incomePrice" label="收益"></el-table-column>
      <el-table-column prop="incomeCurrency" label="收益币种"></el-table-column>
      <!-- <el-table-column prop="tags" label="所属标签"></el-table-column> -->
      <el-table-column prop="type" label="礼物类型">
        <template slot-scope="scope">
          {{giftTypeOptions[scope.row.type]}}
        </template>
      </el-table-column>
      <el-table-column prop="totalUser" label="送出人数"></el-table-column>
      <el-table-column prop="totalSent" label="累计送出"></el-table-column>
      <el-table-column prop="scene" label="场景">
        <template slot-scope="scope">
          {{scope.row.scene?sceneOptions[scope.row.scene]:'全部'}}
        </template>
      </el-table-column>
      <el-table-column prop="statusTxt" label="当前状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status" class="success">已上架</span>
          <span v-else class="warning">已下架</span>
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="更新时间">
        <template slot-scope="scope">
          <span>{{scope.row.updateTime | formatDate}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="210">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="toEdit(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="small"
            type="warning" 
            @click="unmountFunc(scope.row)"
            >{{scope.row.status?'下架':'上架'}}</el-button
          >
          <el-button
            size="small"
            type="danger" 
            @click="delFunc(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
    <el-dialog  :close-on-click-modal="false" :title="`${ruleForm.id?'编辑':'添加'}礼物`" :visible.sync="addVisible" width="650px" top="3vh">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="礼物名称" prop="giftName" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.giftName"
          ></el-input>
        </el-form-item>
        <el-form-item label="礼物类型" prop="giftType" :rules="rules.changeRule">
          <el-select
            clearable
            v-model="ruleForm.giftType"
            placeholder="类型"
          >
            <el-option
              v-for="(item,key) in giftTypeOptions2"
              :key="key"
              :label="item"
              :value="Number(key)">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="特效" prop="type" :rules="rules.changeRule">
          <el-select
            clearable
            v-model="ruleForm.type"
            placeholder="类型"
          >
            <el-option
              v-for="(item,key) in giftTypeOptions"
              :key="key"
              :label="item"
              :value="Number(key)">
            </el-option>
          </el-select>
        </el-form-item>
        <div class="flex">
          <el-form-item label="礼物图片" prop="giftLogo" :rules="rules.typeIcon">
            <uploadImg @change="uploadImgChange" :imageFile="[{url:ruleForm.giftLogo}]"></uploadImg>
          </el-form-item>
          <el-form-item label="礼物特效（JSON格式）" v-if="ruleForm.type==1">
            <uploadImg @change="uploadImgChange2" textTip="上传文件" :reqType="{'type':'FILE'}" :imageFile="[{url:ruleForm.resourceUrl}]"></uploadImg>
          </el-form-item>
        </div>
        <!-- <el-form-item label="所属标签" prop="tags" :rules="rules.changeRule">
          <el-select
            clearable
            v-model="ruleForm.tags"
            placeholder="类型"
          >
            <el-option
              v-for="(item,index) in labelOptions"
              :key="index"
              :label="item.text"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="消费币种" prop="costCurrency" :rules="rules.changeRule">
          <el-select
            clearable
            v-model="ruleForm.costCurrency"
            placeholder="类型"
          >
            <el-option
              v-for="(item,index) in costCoinOptions"
              :key="index"
              :label="item.text"
              :value="item.text">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="消费数量" prop="costPrice" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.costPrice"
            
          ></el-input>
        </el-form-item>
        <el-form-item label="获益币种" prop="incomeCurrency" :rules="rules.changeRule">
          <el-select
            clearable
            v-model="ruleForm.incomeCurrency"
            placeholder="类型"
          >
            <el-option
              v-for="(item,index) in costCoinOptions"
              :key="index"
              :label="item.text"
              :value="item.text">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="获益数量" prop="incomePrice" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.incomePrice"
            
          ></el-input>
        </el-form-item>
        <el-form-item label="场景">
          <el-select
            clearable
            v-model="ruleForm.scene"
            placeholder="类型"
          >
            <el-option
              v-for="(item,key) in sceneOptions"
              :key="key"
              :label="item"
              :value="Number(key)">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFunc">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import uploadImg from '@/components/uploadImg'
import pagination from '@/components/pagination'
export default {
  data() {
    return {
      totalNum: 0,
      addVisible: false,
      ruleForm: {status:0},
      searchForm: {
        name:undefined,
        pageNo: 1,
        pageSize: 10
      },
      sceneOptions: {
        '0':'群聊',
        '1':'私聊'
      },
      giftTypeOptions: {
        '0':'无特效',
        '1':'有特效'
      },
      giftTypeOptions2: {
        '0':'普通礼物',
        '1':'幸运礼物',
        '2':'宝箱礼物',
      },
      labelOptions: [
        { value: 1, text: '适合初学者的NFT 01' },
        { value: 2, text: '适合初学者的NFT 02' },
        { value: 3, text: '适合初学者的NFT 03' }
      ],
      costCoinOptions: [
        { value: '1', text: 'talk' },
        { value: '2', text: 'afa' },
        { value: '3', text: 'coin' }
      ],
      tableData: [],
      dialogVisible: false,
      rules: {
        blurRule: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        changeRule: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        typeIcon: [{ required: true, message: "请上传图片", trigger: "change" }],
      },
    };
  },
  components:{uploadImg,pagination},
  created() {
    this.getTableList();
  },
  methods: {
    addFunc(){
      this.ruleForm = {};
      this.addVisible = true;
    },
    uploadImgChange(val){
      if(val&&val.length>0) {
        this.ruleForm.giftLogo=val[0].url
      }
    },
    uploadImgChange2(val){
      if(val&&val.length>0) {
        this.ruleForm.resourceUrl=val[0].url
      }
    },
    toEdit(data) {
      this.ruleForm = {...data}
      this.addVisible = true;
    },
    async submitFunc() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let res = await this.$http.giftSave({...this.ruleForm});
          if (res) {
            this.$message.success("修改成功！");
            this.getTableList();
            this.addVisible = false;
            this.$refs.ruleForm.resetFields();
          }
        }
      });
    },
    async getTableList(val) {
      if(val) {
        this.searchForm.pageNo = 1
      }
      let res = await this.$http.giftList({...this.searchForm});
      if (res) {
        this.tableData = res.result.data;
        this.totalNum = res.result.totalCount;
      }
    },

    unmountFunc(row) {
      this.$confirm(`确定要${row.status?'下架':'上架'}么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.giftSave({ ...row , status:row.status==0?1:0 })
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
          }
        })
        .catch(() => {});
    },
    delFunc(row) {
      this.$confirm(`确定要删除么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.giftDelete([row.id])
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.icon{
  width: 60px;
}
</style>
