<template>
  <div>
    <div class="searchBox">
      <div class="searchLeft">
        <!-- <div>
          <el-input
              size="medium"
              clearable
              v-model="searchForm.name"
              placeholder="请输入道具名称"
              @keyup.enter.native="getTableList"
          ></el-input>
        </div>
        <el-button size="medium" type="primary" icon="el-icon-search" @click="getTableList(true)">搜索</el-button> -->
      </div>
      <div class="searchRight">
        <el-button
          size="medium"
          type="warning"
          icon="el-icon-plus"
          @click="addFunc"
          >新增</el-button
        >
      </div>
    </div>

    <el-table border :data="tableData" height="76vh" style="width: 99%">
      <el-table-column prop="id" label="id"></el-table-column>
      <el-table-column prop="name" label="名称"></el-table-column>
      <el-table-column prop="costPrice" label="支付价格"></el-table-column>
      <el-table-column prop="costCurrency" label="支付币种"></el-table-column>
      <el-table-column prop="type" label="道具类型">
        <template slot-scope="scope">
          {{scope.row.type==0?'进场特效':'头像框'}}
        </template>
      </el-table-column>
      <el-table-column prop="img" label="道具图片">
        <template slot-scope="scope">
          <img :width="80" :src="scope.row.icon" />
        </template>
      </el-table-column>
      <el-table-column prop="img" label="进场飘屏">
        <template slot-scope="scope">
          <img :width="80" :src="scope.row.screenUrl" />
        </template>
      </el-table-column>
      <el-table-column prop="resourceUrl" label="道具特效" show-overflow-tooltip></el-table-column>
      <el-table-column label="是否展示">
        <template slot-scope="scope">
          {{ scope.row.showShop==0?'否':'是' }}
        </template>
      </el-table-column>
      <el-table-column label="目标用户">
        <template slot-scope="scope">
          {{ scope.row.targetUser==0?'无':scope.row.targetUser==1?'普通用户':scope.row.targetUser==2?'钱包用户':'nft用户' }}
        </template>
      </el-table-column>
      <el-table-column label="是否展示">
        <template slot-scope="scope">
          {{ scope.row.showShop==0?'否':'是' }}
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status" class="success">已上架</span>
          <span v-else class="warning">已下架</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="toEdit(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="small"
            type="warning" 
            @click="unmountFunc(scope.row)"
            >{{scope.row.status?'下架':'上架'}}</el-button
          >
          <!-- <el-button
            size="small"
            type="danger" 
            @click="delFunc(scope.row)"
            >删除</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>
    <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
    <el-dialog  :close-on-click-modal="false" :title="`${ruleForm.id?'编辑':'添加'}道具`" :visible.sync="addVisible" width="600px" top="5vh">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="道具名称" prop="name" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="道具图片" prop="icon" :rules="rules.typeIcon">
          <uploadImg @change="uploadImgChange" :imageFile="[{url:ruleForm.icon}]"></uploadImg>
        </el-form-item>
        <el-form-item label="进场飘屏">
          <uploadImg @change="uploadImgChange3" :imageFile="[{url:ruleForm.screenUrl}]"></uploadImg>
        </el-form-item>
        
        <el-form-item label="花费价格" prop="costPrice" :rules="rules.blurRule">
          <el-input
            v-model.number="ruleForm.costPrice"
            type="number"
            placeholder="请输入内容"
          >
            <template #append>talk</template>
          </el-input>
        </el-form-item>
        
        <el-form-item label="道具类型" prop="type" :rules="rules.changeRule">
          <el-radio-group v-model="ruleForm.type">
            <el-radio :label="0">进场特效</el-radio>
            <el-radio :label="1">头像框</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否展示" prop="showShop" :rules="rules.changeRule">
          <el-radio-group v-model="ruleForm.showShop">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="目标用户" prop="targetUser" :rules="rules.changeRule">
          <el-radio-group v-model="ruleForm.targetUser">
            <el-radio :label="0">无</el-radio>
            <el-radio :label="1">普通用户</el-radio>
            <el-radio :label="2">钱包用户</el-radio>
            <el-radio :label="3">nft用户</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="道具特效 （JSON格式）" prop="resourceUrl" :rules="rules.typeIcon" v-if="ruleForm.type==0">
          <uploadImg @change="uploadImgChange2" textTip="上传文件" :reqType="{'type':'FILE'}" :imageFile="[{url:ruleForm.resourceUrl}]"></uploadImg>
        </el-form-item>
        
      </el-form>
      
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFunc">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import uploadImg from '@/components/uploadImg'
import pagination from '@/components/pagination'
export default {
  data() {
    return {
      totalNum: 0,
      addVisible: false,
      issueVisible: false,
      ruleForm: {},
      issueForm: {},
      searchForm: {
        name:null,
        pageNo: 1,
        pageSize: 10
      },
      tableData: [],
      dialogVisible: false,
      rules: {
        blurRule: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        changeRule: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        typeIcon: [{ required: true, message: "请上传图片", trigger: "change" }],
      },
    };
  },
  components:{uploadImg,pagination},
  created() {
    this.getTableList();
  },
  methods: {
    addFunc(){
      this.ruleForm = {status:0}
      this.addVisible = true;
    },
    uploadImgChange(val){
      if(val&&val.length>0) {
        this.ruleForm.icon=val[0].url
      }
    },
    uploadImgChange2(val){
      if(val&&val.length>0) {
        this.ruleForm.resourceUrl=val[0].url
      }
    },
    uploadImgChange3(val){
      if(val&&val.length>0) {
        this.ruleForm.screenUrl=val[0].url
      }
    },
    toEdit(data) {
      //编辑
      this.ruleForm = {...data};
      this.addVisible = true;
    },
    async submitFunc() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.ruleForm.costCurrency = 'talk'
          let res = await this.$http.userPropsSave({...this.ruleForm});
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
            this.addVisible = false;
            this.$refs.ruleForm.resetFields();
          }
        }
      });
    },
    async getTableList(val) {
      if(val) {
        this.searchForm.pageNo = 1
      }
      let res = await this.$http.userPropsConfigList({...this.searchForm});
      if (res) {
        this.tableData = res.result.data;
        this.totalNum = res.result.totalCount;
      }
    },
    unmountFunc(row) {
      this.$confirm(`确定要${row.status?'下架':'上架'}么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.userPropsSave({ ...row , status:row.status==0?1:0 })
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
          }
        })
        .catch(() => {});
    },
    delFunc(row) {
      this.$confirm(`确定要删除么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.userPropsDel({id:row.id})
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>

</style>
