<template>
  <div>
    <div class="searchBox">
      <div class="searchLeft">
        <div>
          <el-input
              size="medium"
              clearable
              v-model="searchForm.userId"
              placeholder="请输入用户id"
              @keyup.enter.native="getTableList"
          ></el-input>
        </div>
        <div>
          <el-select
            clearable
            size="medium"
            v-model="searchForm.giftType"
            placeholder="类型"
          >
            <el-option
              v-for="(item,key) in typeOptions"
              :key="Number(key)"
              :label="item"
              :value="Number(key)">
            </el-option>
          </el-select>
        </div>
        <div>
          时间：
          <el-date-picker
            size="medium"
            v-model="searchForm.startTime"
            type="date"
            value-format="timestamp"
            @change="getTableList"
            placeholder="开始时间"
          ></el-date-picker>
          <span> — </span>
          <el-date-picker
            size="medium"
            v-model="searchForm.endTime"
            type="date"
            value-format="timestamp"
            @change="getTableList"
            placeholder="结束时间"
          ></el-date-picker>
        </div>
        <el-button size="medium" type="primary" icon="el-icon-search" @click="getTableList(true)">搜索</el-button>
      </div>
      <div class="searchRight">
        <el-button
          size="medium"
          type="warning"
          icon="el-icon-plus"
          @click="addFunc"
          >下发礼物</el-button
        >
      </div>
    </div>

    <el-table border :data="tableData" height="76vh" style="width: 99%" @sort-change="changeSort">
      <el-table-column prop="userId" label="用户id"></el-table-column>
      <el-table-column prop="userName" label="用户昵称"></el-table-column>
      <el-table-column prop="img" label="礼物类型">
        <template slot-scope="scope">
          <span>{{typeOptions[scope.row.goodsType]}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="giftId" label="礼物ID"></el-table-column>
      <el-table-column label="图片" width="80">
        <template slot-scope="scope">
          <img :src="scope.row.icon" alt="" style="width: 50px;height: 50px;">
        </template>
      </el-table-column>
      <el-table-column prop="giftName" label="礼物名称"></el-table-column>
      <el-table-column prop="costPrice" label="单价"></el-table-column>
      <el-table-column prop="num" label="数量"></el-table-column>
      <el-table-column prop="img" label="总价">
        <template slot-scope="scope">
          <span>{{scope.row.costPrice*scope.row.num}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="下发时间" sortable>
        <template slot-scope="scope">
          <span>{{scope.row.createTime | formatDate}}</span>
        </template>
      </el-table-column>
    </el-table>
    <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
    <el-dialog  :close-on-click-modal="false" title="下发礼物" :visible.sync="addVisible" width="700px">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="用户ID（英文逗号分割）" prop="userIds" :rules="rules.blurRule">
            <el-input
              type="textarea"
              :rows="5"
              placeholder="请输入内容"
              v-model="ruleForm.userIds">
            </el-input>
        </el-form-item>
        <el-form-item label="道具类型" prop="type" :rules="rules.changeRule">
          <el-select
            size="medium"
            clearable
            v-model="ruleForm.type"
             @change="changeType"
            placeholder="道具类型"
          >
            <el-option
              v-for="(item,key) in typeOptions"
              :key="key"
              :label="item"
              :value="key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="增减选项" prop="compute" :rules="rules.blurRule">
          <el-select
            size="medium"
            clearable
            v-model="ruleForm.compute"
            placeholder="增减选项"
          >
            <el-option
              label="增加"
              :value="true">
            </el-option>
            <el-option
              label="减少"
              :value="false">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="过期时间" v-if="ruleForm.type==1">
          <el-date-picker
            size="medium"
            v-model="ruleForm.overdue"
            type="datetime"
            value-format="timestamp"
            @change="getTableList"
            placeholder="过期时间"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="通话券数量" prop="callNum" :rules="rules.blurRule" v-if="ruleForm.type==3">
          <el-input
            v-model="ruleForm.callNum"
            placeholder="请输入数量"
          ></el-input>
        </el-form-item>
        <el-form-item label="物品" v-if="ruleForm.type==1||ruleForm.type==2">
          <div v-for="(item,index) in ruleForm.goods" :key="item.id" class="configBox">
            <div class="item">
              <el-form-item label="" label-width="0">
                <el-input
                  v-model.number="item.id"
                  style="width: 100px !important"
                  disabled
                ></el-input>
              </el-form-item>
            </div>
            <div class="item">
              <el-form-item label="" label-width="0">
                <el-input
                  v-model.number="item.name"
                  disabled
                  style="width: 140px !important"
                ></el-input>
              </el-form-item>
            </div>
            <div class="item">
              <el-form-item label="" label-width="0" :prop="'goods.' + index + '.num'" :rules="rules.blurRule">
                <el-input
                  v-model="item.num"
                  placeholder="请输入数量"
                  :disabled="ruleForm.type==1"
                ></el-input>
              </el-form-item>
            </div>
            <div class="item">
              <el-button type="danger" icon="el-icon-delete" circle @click="delGoodsFunc(index)" v-if="ruleForm.goods.length>1?true:index>0"></el-button>
            </div>
          </div>
          <el-button
            size="medium"
            type="warning"
            @click="addGoodsFunc"
            >选择</el-button
          >
        </el-form-item>
      </el-form>
      
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFunc">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog  :close-on-click-modal="false"
      :title="`选择${ruleForm.type==1?'道具':'礼物'}`"
      :visible.sync="dialogVisible"
      top="3vh"
      width="600px">
      <div class="searchBox">
      <div class="searchLeft">
      </div>
      <div class="searchRight" v-if="ruleForm.type==2">
        <p>已选：{{multipleSelectionApi?.length}} 个</p>
      </div>
    </div>
      <el-table v-show="ruleForm.type==2 || ruleForm.type==1"
        height="70vh"
        border
        :data="goodsTableList"
        ref="giftTable"
        @selection-change="handleSelectionChangeApi"
        row-key="id"
        style="width: 100%">
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
          width="100"
          prop="id"
          label="id">
        </el-table-column>
        <el-table-column label="图片">
          <template slot-scope="scope">
            <img :src="scope.row.giftLogo||scope.row.icon" alt="" style="width: 50px;height: 50px;">
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="名称">
          <template slot-scope="scope">
            <span>{{scope.row.giftName||scope.row.name}}</span>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible= false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible= false">确 定</el-button>
    </span>
    </el-dialog>
  </div>
</template>

<script>
import uploadImg from '@/components/uploadImg'
import pagination from '@/components/pagination'
export default {
  data() {
    return {
      totalNum: 0,
      addVisible: false,
      typeOptions:{
        '1':'装饰', 
        '2':'礼物', 
        '3':'通话卷'
      },
      ruleForm: {
        goods:[]
      },
      multipleSelectionApi:[],
      multipleSelectionApi2:[],
      searchForm: {
        name:null,
        sortOrder:null,
        sortField:null,
        pageNo: 1,
        pageSize: 10
      },
      tableData: [],
      giftList:[],
      propsList:[],
      dialogVisible: false,
      rules: {
        blurRule: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        changeRule: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        typeIcon: [{ required: true, message: "请上传图片", trigger: "change" }],
      },
      edit:{}
    };
  },
  components:{uploadImg,pagination},
  computed:{
    goodsTableList(){
      if(this.ruleForm.type==1){
        return this.propsList
      }else if(this.ruleForm.type==2){
        return this.giftList
      }
    }
  },
  created() {
    this.getTableList();
    this.getGiftList();
    this.getPropsList();
  },
  methods: {
    changeType(){
      this.ruleForm.goods=[]
      this.multipleSelectionApi=[]
    },
    handleSelectionChangeApi(val) {
      this.multipleSelectionApi = val.map(item=>{
        let num = this.ruleForm.type==1?1:''
        return {...item,name:item.giftName||item.name,num}
      })
      this.ruleForm.goods=this.multipleSelectionApi
    },
    delGoodsFunc(n){
      this.ruleForm.goods.splice(n,1)
    },
    addGoodsFunc(){
      this.multipleSelectionApi2=this.ruleForm.goods
      this.dialogVisible=true;
      this.$nextTick(()=>{
        this.$refs.giftTable.clearSelection();
        this.goodsTableList.forEach(row => {
          if(this.multipleSelectionApi2.findIndex(x=>x.id==row.id)!==-1){
            setTimeout(() => {
              this.$refs.giftTable.toggleRowSelection(row, true); 
            });
          }
        });
      })
    },
    addFunc(){
      this.ruleForm={goods:[]};
      this.addVisible = true;
    },
    toEdit(data) {
      //编辑
      this.edit = data;
      this.ruleForm = this.$selfFunc.toEdit({...this.ruleForm},{...data});
      this.addVisible = true;
    },
    async submitFunc() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if(this.ruleForm.type!=3 && this.ruleForm.goods.length==0){
            this.$message.error('请选择物品')
            return
          }
          let ruleForm = {...this.ruleForm}
          ruleForm.userIds = ruleForm.userIds.split(',')
          if(ruleForm.type==1 && !ruleForm.overdue){
            ruleForm.overdue = 0
          }
          let res = await this.$http.propsIssued({...ruleForm});
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
            this.addVisible = false;
            this.$refs.ruleForm.resetFields();
          }
        }
      });
    },
    async getTableList(val) {
      if(val) {
        this.searchForm.pageNo = 1
      }
      let res = await this.$http.propsIssuedList({...this.searchForm});
      if (res) {
        this.tableData = res.result.data;
        this.totalNum = res.result.totalCount;
      }
    },
    async getGiftList(){
      let res = await this.$http.giftList({pageNo:1,pageSize:1000,status:1,giftType:0});
      if (res) {
        this.giftList = res.result.data; 
      }
    },
    async getPropsList(){
      let res = await this.$http.userPropsConfigList({pageNo:1,pageSize:1000,status:1});
      if (res) {
        this.propsList = res.result.data; 
      }
    },
    changeSort(val) {
      if (val.order) {
        this.searchForm.sortOrder = val.order === "ascending" ? 'ascend' : 'descend';
        this.searchForm.sortField = val.prop;
      } else {
        this.searchForm.sortOrder = "";
        this.searchForm.sortField = "";
      }
      this.getTableList(true);
    },
  },
};
</script>

<style scoped>
.configBox{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}
</style>
