<template>
  <div>
    <!-- <div class="searchBox">
      <div class="searchLeft">
        <div>
          <el-input
              size="medium"
              clearable
              v-model="searchForm.name"
              placeholder="请输入礼物名称"
              @keyup.enter.native="getTableList"
          ></el-input>
        </div>
        <el-button size="medium" type="primary" icon="el-icon-search" @click="getTableList">搜索</el-button>
      </div>
      <div class="searchRight">
        <el-button
          size="medium"
          type="warning"
          icon="el-icon-plus"
          @click="addFunc"
          >新增</el-button
        >
      </div>
    </div> -->
    <el-table border :data="tableData" height="76vh" style="width: 99%">
      <el-table-column prop="id" label="id"></el-table-column>
      <el-table-column prop="giftName" label="礼物名称"></el-table-column>
      <el-table-column prop="costPrice" label="价格">
        <template slot-scope="scope">
          {{scope.row.costPrice + ' 钻石'}}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="toEdit(scope.row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination> -->
    <el-dialog  :close-on-click-modal="false" :title="`${ruleForm.id?'编辑':'添加'}幸运礼物`" :visible.sync="addVisible" width="1100px" top="3vh">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="礼物名称">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.giftName"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="价格">
          <p>{{ruleForm.costPrice}} 钻石</p>
        </el-form-item>
        <div class="giftConfigHeaderBox">
          <p class="id">档位ID</p>
          <p>返Talk币</p>
          <p>浮动Talk币</p>
          <p>库存</p>
          <p>全局房间IM消息</p>
          <!-- <p class="flex">单独动效</p> -->
          <p>操作</p>
        </div>
        <div class="giftConfigContentBox">
          <div v-for="(item,index) in ruleForm.config" :key="index" class="configBox">
            <p class="id">{{index+1}}</p>
            <div class="item">
              <el-form-item label="" label-width="0" :prop="'config.' + index + '.rebates'" :rules="rules.blurRule">
                <el-input
                  v-model.number="item.rebates"
                  style="width:100% !important"
                ></el-input>
              </el-form-item>
            </div>
            <div class="item">
              <el-form-item label="" label-width="0" :prop="'config.' + index + '.addition'" :rules="rules.blurRule">
              <el-input
                v-model.number="item.addition"
                style="width:100% !important"
              ></el-input>
            </el-form-item>
            </div>
            <div class="item">
              <el-form-item label="" label-width="0" :prop="'config.' + index + '.inventory'" :rules="rules.blurRule">
              <el-input
                v-model.number="item.inventory"
                style="width:100% !important"
              ></el-input>
            </el-form-item>
            </div>
            <div class="item">
              <el-form-item label="" label-width="0"  :prop="'config.' + index + '.hasEffects'" :rules="rules.changeRule">
              <el-select v-model="item.hasEffects" placeholder="请选择" style="width:100% !important">
                <el-option label="否" :value="0"></el-option>
                <el-option label="是" :value="1"></el-option>
              </el-select>
            </el-form-item>
            </div>
            <!-- <div class="flex">
              <el-form-item label="" label-width="0" :prop="'config.' + index + '.amount'" :rules="rules.blurRule">
                <el-input
                  v-model="item.amount"
                  style="width:100% !important"
                ></el-input>
              </el-form-item>
            </div> -->
            <div class="item">
              <el-button type="danger" icon="el-icon-delete" style="margin-bottom:20px" circle @click="delFunc(item,index)" v-if="ruleForm.config.length>1?true:index>0"></el-button>
            </div>
          </div>
        </div>
        <el-form-item label="">
          <el-button
            size="medium"
            type="warning"
            @click="addReward"
            >添加档位</el-button
          >
          
        </el-form-item>
      </el-form>
      
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFunc">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import uploadImg from '@/components/uploadImg'
import pagination from '@/components/pagination'
export default {
  data() {
    return {
      totalNum: 0,
      addVisible: false,
      ruleForm: {config:[]},
      searchForm: {
        name:undefined,
        pageNo: 1,
        pageSize: 10
      },
      giftTypeOptions: {
        '0':'无特效',
        '1':'有特效'
      },
      tableData: [],
      rules: {
        blurRule: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        changeRule: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        typeIcon: [{ required: true, message: "请上传图片", trigger: "change" }],
      },
      info:{}
    };
  },
  components:{uploadImg,pagination},
  created() {
    this.getTableList();
  },
  methods: {
    addReward(){
      this.ruleForm.config.push({})
    },
    delReward(n){
      this.ruleForm.config.splice(n,1)
    },
    async getInfo(row){
      let res = await this.$http.luckyConfigList({giftId:row.id})
      this.ruleForm = {config:res.data,costPrice:row.costPrice,giftName:row.giftName,giftId:row.id}
    },
    async toEdit(row) {
      this.info = row
      await this.getInfo(row)
      this.addVisible = true;
    },
    async submitFunc() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.ruleForm.config = this.ruleForm.config.map(x=>{
            if(!x.id){
              x.initInventory = x.inventory
              x.giftId = this.ruleForm.giftId
            }
            return x
          })
          let res = await this.$http.luckyConfigSave([...this.ruleForm.config]);
          if (res) {
            this.$message.success("修改成功！");
            this.getTableList();
            this.addVisible = false;
            this.$refs.ruleForm.resetFields();
          }
        }
      });
    },
    async getTableList(val) {
      if(val) {
        this.searchForm.pageNo = 1
      }
      let res = await this.$http.getGiftList({...this.searchForm,giftType:1});
      if (res) {
        this.tableData = res.result.data;
        this.totalNum = res.result.totalCount;
      }
    },
    delFunc(row,index) {
      if(!row.id){
        this.delReward(index)
      }else{
        this.$confirm(`删除后不可恢复，确定要删除么？`, "提示", {
          confirmButtonText: "确定",
        cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            let res = await this.$http.luckyConfigRemove({...row})
          if (res) {
            this.$message.success("操作成功！");
            this.getInfo(this.info);
          }
        })
        .catch(() => {});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon{
  width: 60px;
}
.giftConfigHeaderBox{
  display: flex;
  align-items: center;
  height: 40px;
  background-color: #F5F7FA;
  p{
    flex: 1;
    padding: 0 10px;
    &.id{
      width: 70px;
      flex: none;
    }
    &.flex{
      width: 200px;
      flex: none;
    }
  }
}

</style>
<style lang="scss">
.giftConfigContentBox{
  margin-bottom: 20px;
  .configBox{
    display: flex;
    align-items: center;
    height: 80px;
    border-bottom: 1px solid #ddd;
    .item{
      flex: 1;
      padding: 0 10px;
    }
    .id{
      width: 70px;
      flex: none;
      margin-bottom: 22px;
    }
    .flex{
      width: 200px;
      flex: none;
    }
    .el-input{
      width: 100% !important;
    }
  }
}
</style>