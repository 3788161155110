<template>
  <div>
    <div class="searchBox">
      <div class="searchLeft">
        <!-- <div>
          <el-input
              size="medium"
              clearable
              v-model="searchForm.name"
              placeholder="请输入标签名称"
              @keyup.enter.native="getTableList"
          ></el-input>
        </div>
        <el-button size="medium" type="primary" icon="el-icon-search" @click="getTableList">搜索</el-button> -->
      </div>
      <div class="searchRight">
        <el-button
          size="medium"
          type="warning"
          icon="el-icon-plus"
          @click="addFunc"
          >新增</el-button
        >
      </div>
    </div>
    <el-table border :data="tableData" height="76vh" style="width: 99%">
      <el-table-column prop="sort" label="排序"></el-table-column>
      <el-table-column prop="tagName" label="标签名称"></el-table-column>
      <el-table-column label="背景颜色">
        <template slot-scope="scope">
          <div class="colorBox">
            <p>{{scope.row.color}}</p>
            <div :style="{backgroundColor:formatColorSixteen(scope.row.color)}" class="colorBg"></div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="字体颜色">
        <template slot-scope="scope">
          <div class="colorBox">
            <p>{{scope.row.fontColor}}</p>
            <div :style="{backgroundColor:formatColorSixteen(scope.row.fontColor)}" class="colorBg"></div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="category" label="列表标签可见人群">
        <template slot-scope="scope">
          <span>{{scope.row.whiteConfig==2?'全部':scope.row.whiteConfig==0?'非白名单用户':'白名单用户'}}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="feedStatusTxt" label="首页推荐"></el-table-column> -->
      <el-table-column label="操作" width="160">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="toEdit(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="small"
            type="danger" 
            @click="delFunc(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
    <el-dialog  :close-on-click-modal="false" :title="`${ruleForm.id?'编辑':'添加'}标签`" :visible.sync="addVisible" width="650px">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="130px"
        class="demo-ruleForm"
      >
        <el-form-item label="标签名称" prop="tagName" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.tagName"
          ></el-input>
        </el-form-item>
        <el-form-item label="标签背景颜色" prop="color" :rules="rules.changeRule">
          <el-color-picker v-model="ruleForm.color"></el-color-picker>
        </el-form-item>
        <el-form-item label="标签字体颜色" prop="fontColor" :rules="rules.changeRule">
          <el-color-picker v-model="ruleForm.fontColor"></el-color-picker>
        </el-form-item>
        <el-form-item label="列表可见用户" prop="whiteConfig" :rules="rules.changeRule">
          <el-checkbox-group v-model="ruleForm.whiteConfig">
            <el-checkbox :label="0">非白名单用户</el-checkbox>
            <el-checkbox :label="1">白名单用户</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="排序">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.sort"
          ></el-input>
        </el-form-item>
      </el-form>
      
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFunc">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pagination from '@/components/pagination'
export default {
  data() {
    return {
      totalNum: 0,
      addVisible: false,
      ruleForm: {},
      searchForm: {
        pageNo: 1,
        pageSize: 10
      },
      tableData: [],
      dialogVisible: false,
      rules: {
        blurRule: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        changeRule: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        typeIcon: [{ required: true, message: "请上传图片", trigger: "change" }],
      },
    };
  },
  components:{pagination},
  created() {
    this.getTableList();
  },
  methods: {
    addFunc(){
      this.ruleForm = {whiteConfig: []};
      this.ruleForm.taskNum = 0;
      this.addVisible = true;
    },
    toEdit(data) {
      //编辑
      this.ruleForm = {...data}
      this.ruleForm.color = this.formatColorSixteen(this.ruleForm.color)
      this.ruleForm.fontColor = this.formatColorSixteen(this.ruleForm.fontColor)
      this.ruleForm.whiteConfig = this.ruleForm.whiteConfig==2?[0,1]:[Number(this.ruleForm.whiteConfig)]
      console.log(this.ruleForm.whiteConfig)
      this.addVisible = true;
    },
    async submitFunc() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let ruleForm = {...this.ruleForm}
          ruleForm.color = this.formatColorTen(ruleForm.color);
          ruleForm.fontColor = this.formatColorTen(ruleForm.fontColor);
          ruleForm.whiteConfig = ruleForm.whiteConfig.length==2?2:ruleForm.whiteConfig[0]
          let res = await this.$http.roomTagConfigSave({...ruleForm});
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
            this.addVisible = false;
            this.$refs.ruleForm.resetFields();
          }
        }
      });
    },
    formatColorTen(color){
      const hexColorWithHash = color
      const hexColor = hexColorWithHash.slice(1); 
      const decimalColor = parseInt('FF'+hexColor, 16);
      return decimalColor
    },
    formatColorSixteen(color){
      if(!color) return;
      const hex = '#' + color.toString(16).slice(2);
      return hex
    },
    async getTableList(val) {
      if(val) {
        this.searchForm.pageNo = 1
      }
      let res = await this.$http.roomTagConfigList({...this.searchForm});
      if (res) {
        this.tableData = res.result.records;
        this.totalNum = res.result.total;
      }
    },
    delFunc(row) {
      this.$confirm(`确定要删除么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.roomTagConfigDel({id:row.id})
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.icon{
  width: 60px;
}
.colorBox{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.colorBg{
  width: 50px;
  height: 30px;
  border: 1px solid #ccc;
}
</style>
