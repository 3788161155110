import { render, staticRenderFns } from "./userListInfo.vue?vue&type=template&id=596ba246&scoped=true"
import script from "./userListInfo.vue?vue&type=script&lang=js"
export * from "./userListInfo.vue?vue&type=script&lang=js"
import style0 from "./userListInfo.vue?vue&type=style&index=0&id=596ba246&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "596ba246",
  null
  
)

export default component.exports