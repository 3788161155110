import axios from 'axios'
import { Message } from "element-ui";
import { VueAxios } from './axios'
import config from "@/config/env";

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: config.api,
  timeout: 240 * 1000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    // 从 localstorage 获取 token
    // const token = storage.get(ACCESS_TOKEN)
    if (error.response.status === 403) {
      Message.error(data.message)
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      Message.error('Authorization verification failed')
      sessionStorage.clear()
      window.location.reload();
    }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  const token = sessionStorage.getItem('token')
  if (token) {
      config.headers.Authorization = token
  }
  const { param } = config
  if (param) {
    if (config.method == 'get') {
      config.params = param
    } else {
      config.data = param
    }
  }
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  if(response.data.result){
    return response.data
  }else if(response.data.code===200){   
    return response.data
  }else{
    Message.error(response.data.message)
  }
}, errorHandler)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
