<template>
  <div>
   <div class="searchBox">
    <div class="searchLeft">
        <div>
          <el-input
              size="medium"
              clearable
              v-model="searchForm.userName"
              placeholder="请输入账户名 / 姓名"
              @keyup.enter.native="getTableList"
          ></el-input>
        </div>
        <el-button size="medium" type="primary" icon="el-icon-search" @click="getTableList(true)">搜索</el-button>
      </div>
    <div class="searchRight">
      <el-button size="medium" type="primary" icon="el-icon-plus" @click="addFunc">增加</el-button>
    </div>
   </div>
   <el-table
    border
    :data="tableData"
    height="76vh"
    style="width: 100%">
    <el-table-column
      prop="account"
      label="账户名">
    </el-table-column>
    <el-table-column
      prop="userName"
      label="姓名">
    </el-table-column>
    <el-table-column
      prop="mobile"
      label="电话">
    </el-table-column>
    <el-table-column
      label="角色">
      <template slot-scope="scope">
        <span v-if="scope.row.role">{{scope.row.role.name}}</span>
      </template>
    </el-table-column>
    <el-table-column
      label="正常/禁用">
      <template slot-scope="scope">
        <span v-if="scope.row.enabled==true" class="success">正常</span>
        <span v-else class="danger">禁用</span>
      </template>
    </el-table-column>
    <el-table-column
      prop="way"
      width="400px"
      label="操作">
      <template slot-scope="scope">
        <el-button size="mini" type="primary" @click="editType(scope.row,1)">授权</el-button>
        <el-button size="mini" type="warning" @click="editType(scope.row,2)">修改信息</el-button>
        <el-button size="mini" type="info" @click="editType(scope.row,3)">修改密码</el-button>
        <el-button size="mini" type="warning" @click="updateStatusFunc(scope.row)">{{scope.row.enabled==true?'禁用':'解禁'}}</el-button>
        <el-button size="mini" type="danger" @click="deleteFunc(scope.row)">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
  <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
    <el-dialog  :close-on-click-modal="false"
    :title="!ruleForm.id?'添加账户':'修改账户'"
    :visible.sync="dialogVisible"
    width="430px">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="账户名" prop="account" :rules="rules.blurRule">
            <el-input placeholder="请输入账户名" v-model="ruleForm.account" :disabled="ruleForm.id"></el-input>
        </el-form-item>
        <el-form-item label="用户密码" prop="password" :rules="rules.blurRule" v-if="!ruleForm.id">
            <el-input placeholder="请输入用户密码" v-model="ruleForm.password"></el-input>
        </el-form-item>
        <el-form-item label="用户姓名" prop="userName" :rules="rules.blurRule">
            <el-input placeholder="请输入用户姓名" v-model="ruleForm.userName"></el-input>
        </el-form-item>
        <el-form-item label="电话">
            <el-input placeholder="请输入电话"  v-model="ruleForm.mobile"></el-input>
        </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
    </span>
    </el-dialog>
    <el-dialog  :close-on-click-modal="false"
      title="修改密码"
      :visible.sync="passDialogVisible"
      width="440px">
      <el-form :model="passForm" :rules="rules" ref="passForm" label-width="100px" class="demo-ruleForm">
          <!-- <el-form-item label="旧密码" prop="opass">
              <el-input type="password" placeholder="请输入旧密码" v-model="passForm.opass" style="width:240px"></el-input>
          </el-form-item> -->
          <el-form-item label="新密码" prop="npass">
              <el-input placeholder="请输入新密码" v-model="passForm.password" style="width:240px"></el-input>
          </el-form-item>
          <!-- <el-form-item label="确认密码" prop="cpass">
              <el-input type="password" placeholder="请输入确认密码" v-model="passForm.cpass" style="width:240px"></el-input>
          </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="passDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="resetPassWord('passForm')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog  :close-on-click-modal="false"
      title="授权角色"
      :visible.sync="authDialogVisible"
      width="440px">
      <el-form :model="authForm" :rules="rules" ref="authForm" label-width="100px" class="demo-ruleForm">
         <el-form-item label="用户角色" prop="targetId" :rules="rules.selectRule">
            <el-select size="medium" clearable v-model="authForm.targetId" placeholder="请选择角色" style="width:240px">
              <el-option
                v-for="item in roleList"
                :key="item.id"
                :label="item.name+'('+item.description+')'"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="authDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="authSubmit('authForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pagination from '@/components/pagination'
export default {
  data() {
    var passCheck = (rule, value, callback) => {
      if (value==='') {
        return callback(new Error('输入不能为空'));
      } else if(value.length<5||value.length>20){
        return callback(new Error('密码长度必须是5到20位'));
      } else if (this.passForm.npass!=this.passForm.cpass) {
        return callback(new Error('两次输入密码不一致'));
      } else {
        return callback()
      }
    }
    return {
      totalNum:0,
      searchForm:{
        pageNo: 1,
        pageSize: 100
      },
      tableData: [],
      dialogVisible:false,
      passDialogVisible:false,
      authDialogVisible:false,
      ruleForm:{
        enabled:true
      },
      passForm:{},
      authForm:{},
      rules:{
          blurRule:[
              { required: true, message: '请输入', trigger: 'blur' }
          ],
          selectRule:[
              { required: true, message: '请选择', trigger: 'change' }
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            { min: 5, max: 20, message: '密码最小为8位-20位', trigger: 'blur' }
          ],
          passwordCheck:[
              { required: true, validator: passCheck, trigger: 'blur' }
          ]
      },
      roleList: [],
    };
  },
  components:{pagination},
  created() {
    this.getTableList()
    this.getRoleList()
  },
  methods: {
    submitForm(formName) {
        this.$refs[formName].validate(async (valid) => {
            if (valid) {
                 let ruleForm = {...this.ruleForm}
                 let res = null
                 if(this.ruleForm.id) {
                   ruleForm.password=undefined
                   res = await this.$http.sysUserUpdate({...ruleForm});
                 }else {
                   res = await this.$http.sysUserAdd({...ruleForm});
                 }
                if (res) {
                  this.$refs[formName].resetFields();
                  this.$message.success('操作成功！');
                  this.dialogVisible=false;
                  this.getTableList()
                }
            } else {
            console.log('error submit!!');
            return false;
            }
        });
    },
    resetPassWord(formName){
      this.$refs[formName].validate(async (valid) => {
          if (valid) {
              let passForm = {...this.passForm}
              passForm.cpass=undefined
              let res = await this.$http.sysUserPassword({...passForm});
              if (res) {
                this.$message.success('操作成功！');
                this.passDialogVisible=false;
                this.$refs[formName].resetFields();
              }
          } else {
          console.log('error submit!!');
          return false;
          }
      });
    },
    authSubmit(formName){
      this.$refs[formName].validate(async (valid) => {
          if (valid) {
            let authForm = {...this.authForm}
            authForm.targetId = [authForm.targetId]
              let res = await this.$http.accountAddrole({...authForm});
              if (res) {
                this.$message.success('操作成功！');
                this.authDialogVisible=false;
                this.getTableList();
              }
          } else {
          console.log('error submit!!');
          return false;
          }
      });
    },
    updateStatusFunc(row){
        this.$confirm(`确定要${row.enabled?'禁用':'解禁'} ${row.account} 账户吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          let res = await this.$http.sysUserUpdate({...row,enabled:!row.enabled});
          if (res) {
            this.$message.success('操作成功！');
            this.getTableList()
          }
        }).catch(() => {
                
        });
    },
    deleteFunc(row){
        this.$confirm(`确定要删除 ${row.account} 账户吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          let res = await this.$http.sysUserDelete([row.id]);
          if (res) {
            this.$message.success('操作成功！');
            this.getTableList()
          }
        })
    },
    editType(row,type){
      // type:1授权2修改信息3修改密码
        if (type==1) {
          let authForm = {}
          authForm.id=row.id
          if (row.role&&row.role.id){
            authForm.targetId=row.role.id
          } else {
            authForm.targetId=''
          }
          this.authForm = {...authForm}
          this.authDialogVisible=true
        } else if (type==2) {
          this.ruleForm=row
          this.dialogVisible=true
        } else {
          this.passForm.account=row.account
          this.passDialogVisible=true
        }
    },

    addFunc(){
      this.dialogVisible=true;
      this.ruleForm={enabled:true}
    },
    async getTableList(val){
       let res = await this.$http.sysUserList({...this.searchForm});
        if (res) {
          this.tableData = res.result.data;
          this.totalNum = res.result.totalCount;
        }
    },
    async getRoleList(){
      let res = await this.$http.roleList({
        pageNo: 1,
        pageSize: 100
      });
      if (res) {
        this.roleList=res.result.data
      }
    },
  }
};
</script>

<style scoped>


</style>
