<template>
  <div>
    <div class="searchBox">
      <div class="searchLeft">
        <div>
          <el-input
              size="medium"
              clearable
              v-model="searchForm.userId"
              placeholder="请输入用户id"
              @keyup.enter.native="getTableList"
          ></el-input>
        </div>
        <el-button size="medium" type="primary" icon="el-icon-search" @click="getTableList(true)">搜索</el-button>
        <el-button size="medium" type="primary" icon="el-icon-download" @click="exportExcel">导出</el-button>
      </div>
      <div class="searchRight">
        <el-button
          size="medium"
          type="warning"
          icon="el-icon-plus"
          @click="addFunc"
          >新增</el-button
        >
        
      </div>
    </div>

    <el-table border :data="tableData" height="76vh" style="width: 99%" @sort-change="changeSort">
      <el-table-column prop="id" label="id"></el-table-column>
      <el-table-column prop="userId" label="userId"></el-table-column>
      <el-table-column prop="userName" label="昵称"></el-table-column>
      <el-table-column prop="voucherCount" label="收入通话券数量">
        <template slot-scope="scope">
          <span>{{scope.row.voucherCount || 0}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="count" label="可邀请次数">
        <template slot-scope="scope">
          <span>{{scope.row.count}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button
            size="small"
            type="danger" 
            @click="delFunc(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
    <el-dialog  :close-on-click-modal="false" title="添加白名单" :visible.sync="addVisible" width="600px" top="5vh">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
        class="demo-ruleForm"
      >
         <el-form-item label="用户ID（逗号分割）" prop="ids" :rules="rules.blurRule">
            <el-input
              type="textarea"
              :rows="5"
              placeholder="请输入内容"
              v-model="ruleForm.ids">
            </el-input>
        </el-form-item>
      </el-form>
      
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFunc">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as XLSX from 'xlsx';
import uploadImg from '@/components/uploadImg'
import pagination from '@/components/pagination'
export default {
  data() {
    return {
      totalNum: 0,
      addVisible: false,
      ruleForm:{},
      searchForm: {
        name:null,
        sortOrder:null,
        sortField:null,
        pageNo: 1,
        pageSize: 10
      },
      tableData: [],
      rules: {
        blurRule: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        changeRule: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        typeIcon: [{ required: true, message: "请上传图片", trigger: "change" }],
      },
    };
  },
  components:{uploadImg,pagination},
  created() {
    this.getTableList();
  },
  methods: {
    async exportExcel(){
      let res = await this.$http.userInviteCallList({...this.searchForm,pageNo:1,pageSize:10000});
      if (res) {
        let tableData = res.result.data;
        const worksheet = XLSX.utils.json_to_sheet(tableData);
        // 创建一个新的工作簿
        const workbook = XLSX.utils.book_new();
        // 将工作表添加到工作簿
        XLSX.utils.book_append_sheet(workbook, worksheet, "People");
        // 导出工作簿（创建 XLSX 文件）
        XLSX.writeFile(workbook, "callWhiteList.xlsx");
      }
    },
    addFunc(){
      this.ruleForm = {}
      this.addVisible = true;
    },
    uploadImgChange(val){
      if(val&&val.length>0) {
        this.ruleForm.productUrl=val[0].url
      }
    },
    async submitFunc() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let ids = this.ruleForm.ids.split(',')
          let res = await this.$http.userInviteCallSave(ids);
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
            this.addVisible = false;
            this.$refs.ruleForm.resetFields();
          }
        }
      });
    },
    async getTableList(val) {
      if(val) {
        this.searchForm.pageNo = 1
      }
      let res = await this.$http.userInviteCallList({...this.searchForm});
      if (res) {
        this.tableData = res.result.data;
        this.totalNum = res.result.totalCount;
      }
    },
    changeSort(val) {
      console.log(val)
      if (val.order) {
        this.searchForm.sortOrder = val.order === "ascending" ? 'ascend' : 'descend';
        this.searchForm.sortField = val.prop;
      } else {
        this.searchForm.sortOrder = "";
        this.searchForm.sortField = "";
      }
      this.getTableList(true);
    },
    delFunc(row) {
      this.$confirm(`确定要删除么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.userInviteCallDel({id:row.id})
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>

</style>
