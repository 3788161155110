<template>
    <div class="content">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>基本信息</span>
        </div>
        <div class="info">
          <div class="infoImg">
            <img :src="userInfo.avatar" alt="" v-if="userInfo.avatar">
            <img src="@/assets/images/avart.jpg" alt="" v-else>
            <div>{{userInfo.nickName}}</div>
          </div>
          <div class="subInfo">
            <p>id：{{userInfo.id}}</p>
            <p>国家：{{userInfo.country}}</p>
            <p>绑定钱包：{{userInfo.wallet}}</p>
            <!-- <p>NFT：{{userInfo.nft}}</p> -->
            <p>注册时间：{{userInfo.createTime | formatDate}}</p>
            <p>最后登录时间：{{userInfo.lastLoginTime}}</p>
            <p>绑定邮箱：{{userInfo.email}}</p>
            <p>talk余额：{{userInfo.talkAmount}}</p>
            <p>谷歌邮箱：{{userInfo.googleEmail}}</p>
            <p>注册国家：{{countryName}}</p>
            <p>语言：{{userInfo.lang}}
              <el-button type="text" @click="changeUserInfo">修改</el-button>
            </p>
            <!-- <p>用户来源：{{userInfo.source}}</p> -->

          </div>
        </div>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>标签</span>
        </div>
        <div>
          <el-tag :type="tagColor[index]||tagColor[0]" v-for="(item,index) in userInfo?.tagConfigList" :key="index">{{item.name}}</el-tag>
        </div> 
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>链上统计信息</span>
        </div>
        <el-table border :data="userInfoOnChain" style="width: 99%">
          <el-table-column prop="addressTokenAsset" label="地址持有代币资产"></el-table-column>
          <el-table-column prop="afa" label="Afa"></el-table-column>
          <el-table-column prop="nft" label="持有NFT资产"></el-table-column>
          <el-table-column prop="nftPledge" label="是否NFT质押">
            <template slot-scope="scope">
              {{scope.row?.nftPledge?'是':'否'}}
            </template>
          </el-table-column>
          <el-table-column prop="taskIncome" label="任务收入"></el-table-column>
          <el-table-column prop="nftIncome" label="NFT收入"></el-table-column>
          <!-- <el-table-column prop="roomBonus" label="房间分红"></el-table-column> -->
        </el-table>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>链下统计信息</span>
        </div>
        <el-table border :data="userInfoOffChain" style="width: 99%">
          <el-table-column prop="claimTask" label="接受任务数"></el-table-column>
          <el-table-column prop="finishTask" label="完成任务数"></el-table-column>
          <el-table-column prop="joinRoom" label="加入房间"></el-table-column>
          <el-table-column prop="roomId" label="房间Id"></el-table-column>
          <el-table-column prop="sendSum" label="累计送礼"></el-table-column>
          <el-table-column prop="receiveSum" label="累计收礼"></el-table-column>
          <!-- <el-table-column prop="averageTime" label="平均停留时长"></el-table-column>
          <el-table-column prop="inRoomTime" label="累计停留时长"></el-table-column>
          <el-table-column prop="viewNews" label="查看资讯"></el-table-column> -->
          <el-table-column prop="inviteFriend" label="邀请好友"></el-table-column>
          <!-- <el-table-column prop="loginCount" label="登录次数"></el-table-column> -->
          <el-table-column prop="claimWhiteCount" label="领取白名单次数"></el-table-column>
        </el-table>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>订单记录</span>
          <el-button size="medium" type="primary" icon="el-icon-download" @click="exportExcel" style="float: right;">导出</el-button>
        </div>
        <el-table border :data="tableData" style="width: 99%" @sort-change="changeSort">
          <el-table-column prop="id" label="订单编号"></el-table-column>
          <el-table-column prop="userId" label="用户账号"></el-table-column>
          <el-table-column prop="amount" label="订单金额" sortable>
            <template slot-scope="scope">
              <span>{{scope.row.amount +' '+ scope.row.currency}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="payType" label="消费方式" sortable>
            <template slot-scope="scope">
              <span>{{scope.row.payType == '1' ?'支出':'收入'}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="action" label="订单来源" sortable>
            <template slot-scope="scope">
              <span>{{orderOriginOptions[scope.row.action]||scope.row.action}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="提交时间" sortable>
            <template slot-scope="scope">
              <span>{{scope.row.createTime | formatDate}}</span>
            </template>
          </el-table-column>
        </el-table>
        <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
      </el-card>
      <el-dialog  :close-on-click-modal="false" title="修改信息" :visible.sync="editUserInfoVisible" width="600px">
        <el-form
          :model="editUserInfoForm"
          ref="ruleForm"
          label-width="150px"
          class="demo-ruleForm"
        >
          <el-form-item label="注册国家" prop="countryCode">
            <el-select v-model="editUserInfoForm.countryCode" filterable placeholder="请选择" @change="changeCountryCode">
              <el-option
                v-for="item in countryOptions"
                :key="item.id"
                :label="`${item.countryChineseName} (${item.abv})`"
                :value="item.abv">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="国家语言">
            <el-select v-model="editUserInfoForm.countryCode" placeholder="请选择" disabled>
              <el-option
                v-for="item in countryOptions"
                :key="item.id"
                :label="`${item.languageIso639Code} (${item.countryChineseName})`"
                :value="item.abv">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      
      
        <span slot="footer" class="dialog-footer">
          <el-button @click="editUserInfoVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitEditUserInfoFunc">确 定</el-button>
        </span>
      </el-dialog>
        

    </div>
</template>

<script>
import * as XLSX from 'xlsx';
import pagination from '@/components/pagination'
export default {
  data() {
    return {
      tagColor:['success','info','warning','primary'],
      totalNum: 0,
      searchForm: {
        pageNo: 1,
        pageSize: 10
      },
      tableData: [],
      userInfo:{},
      userInfoOffChain:[],
      userInfoOnChain:[],
      id:null,
      editUserInfoVisible:false,
      editUserInfoForm:{},
      countryOptions:[],
      orderOriginOptions:{
        'task_reward':'任务奖励',
        'room_task_reward':'房间任务奖励',
        'give_call_voucher':'亲密度等级奖励',
        'live_reward':'直播奖励',
        'gift_income':'礼物收入',
        'live_duration_reward':'直播时长奖励',
        'live_stay_reward':'直播停留时长奖励',
        'recharge':'充值',
        'receive_gift':'接受礼物',
        'lucky_gift_rebates':'幸运礼物返现',
        'call_income':'通话收入',
        'app_in_apple_recharge':'apple充值',
        'app_in_luck_yin_recharge':'metago充值',
        'daily_task_reward':'每日任务奖励',
        'gift_cost':'送礼消耗',
        'withdraw':'提现',
        'call':'语音通话',
        'send_gift':'发送礼物',
        'intimacy_reward':'亲密度升级奖励',
        'pledge_income':'质押收入',
      }
    };
  },
  components:{pagination},
  created() {
    this.id=this.$route.query.id
    this.getInfo()
    this.getTableList();
    this.getCountryList()
  },
  computed:{
    countryName(){
      return this.countryOptions.filter(item => item.abv === this.userInfo.countryCode)[0]?.countryChineseName
    }
  },
  methods: {
    changeCountryCode(val){
      this.editUserInfoForm.lang = this.countryOptions.find(item => item.abv === val).languageIso639Code
    },  
    async getCountryList() {
      let res = await this.$http.countryGroupConfigCodeList({pageNo:1,pageSize:1000});
      if (res) {
        this.countryOptions = res.result.data;
      }
    },
    changeUserInfo(){
      this.editUserInfoForm = this.userInfo
      this.editUserInfoVisible = true
      this.editUserInfoForm.lang = this.userInfo.lang
      this.editUserInfoForm.countryCode = this.userInfo.countryCode
    },
    submitEditUserInfoFunc(){
      this.$http.updateUserInfo({...this.editUserInfoForm,userId:this.id}).then(res=>{
        if(res){
          this.$message.success('修改成功')
          this.editUserInfoVisible = false
          this.getInfo()
        }
      })
    },
    changeSort(val) {
      if (val.order) {
        this.searchForm.sortOrder = val.order === "ascending" ? "ascend" : "descend";
        this.searchForm.sortField = val.prop;
      } else {
        this.searchForm.sortOrder = "";
      }
      this.getTableList(true);
    },
    async exportExcel(){
      let res = await this.$http.getOrders({...this.searchForm,userId:this.id,pageNo:1,pageSize:1000});
      if (res) {
        let tableData = res.result.data;
        const worksheet = XLSX.utils.json_to_sheet(tableData);
        // 创建一个新的工作簿
        const workbook = XLSX.utils.book_new();
        // 将工作表添加到工作簿
        XLSX.utils.book_append_sheet(workbook, worksheet, "People");
        // 导出工作簿（创建 XLSX 文件）
        XLSX.writeFile(workbook, "orderList.xlsx");
      }
    },
    async getTableList() {
      let res = await this.$http.getOrders({...this.searchForm,userId:this.id});
      if (res) {
        this.tableData = res.result.data;
        this.totalNum = res.result.totalCount;
      }
    },
    async getInfo() {
      let res = await this.$http.getUserInfo({userId:this.id});
      if (res) {
        this.userInfo = res.data;
        this.userInfoOffChain.push(this.userInfo.userInfoOffChain);
        this.userInfoOnChain.push(this.userInfo.userInfoOnChain);
        console.log(this.userInfoOffChain)
        console.log(this.userInfoOnChain)
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.content{
  .box-card{
    margin-bottom: 10px;
  }
  .head{
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d3cbcb;
  }
  .info{
    display: flex;
    flex-wrap: wrap;
    gap: 100px;
    padding: 20px 0;
    border-bottom: 1px solid #d3cbcb;
    .infoImg{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 300px;
      gap: 10px;
      img{
        width: 120px;
      }
    }
    .subInfo{
      display: flex;
      flex-wrap: wrap;
      flex: 1;
    }
    p{
      width: 50%;
      line-height: 32px;

    }
  }
  .childTask{
    .childAdd{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      
    }
  }
}
</style>