<template>
  <div>
    <div class="searchBox">
      <div class="searchLeft">
        <!-- <div>
          <el-select
            size="medium"
            clearable
            v-model="ruleForm.type"
            placeholder="国家组用途"
          >
            <el-option
              v-for="(item,key) in useOptions"
              :key="key"
              :label="item"
              :value="Number(key)">
            </el-option>
          </el-select>
        </div>
        <el-button size="medium" type="primary" icon="el-icon-search" @click="getTableList(true)">搜索</el-button> -->
      </div>
      <!-- <div class="searchRight">
        <el-button
          size="medium"
          type="warning"
          icon="el-icon-plus"
          @click="addFunc"
          >创建用途</el-button
        >
      </div> -->
    </div>

    <el-table border :data="tableData" height="76vh" style="width: 99%">
      <el-table-column prop="id" label="ID"></el-table-column>
      <el-table-column prop="name" label="国家组用途">
      </el-table-column>
      <el-table-column prop="inventory" label="国家组">
        <template slot-scope="scope">
          <span>{{ scope.row.configList.map(item=>item.name).join(',') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="170">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="toEdit(scope.row)"
            >编辑</el-button
          >
          <el-button size="small" type="warning" @click="toDivision(scope.row)"
            >分区展示</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
    <el-dialog  :close-on-click-modal="false" :title="`${ruleForm.id?'编辑':'添加'}国家组`" :visible.sync="addVisible" width="800px">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="130px"
        class="demo-ruleForm"
      >
        <el-form-item label="国家组用途名称" prop="name"  :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.name"
            disabled
          ></el-input>
        </el-form-item>
        <div v-for="(item,index) in ruleForm.reqList" :key="index" class="groupItem">
          <el-form-item :label="`国家组${index+1}`" :prop="'reqList.' + index + '.name'" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="item.name"
              :disabled="index===0"
            ></el-input>
          </el-form-item>
          <el-form-item label="" label-width="0px"  :prop="'reqList.' + index + '.targetId'" :rules="rules.changeRule">
            <el-select v-model="item.targetId" filterable multiple placeholder="请选择">
              <el-option
                v-for="item in countryOptions"
                :key="item.id"
                :label="`${item.countryChineseName} (${item.abv})`"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <div class="item">
            <el-button type="danger" icon="el-icon-delete" circle @click="delFunc(item,index)" v-if="index>0"></el-button>
          </div>
        </div>
        <el-form-item label="">
          <el-button
            size="medium"
            type="warning"
            @click="addGroup"
            >添加</el-button
          >
        </el-form-item>
        
      </el-form>
      
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFunc">确 定</el-button>
      </span>
    </el-dialog>


    <el-dialog  :close-on-click-modal="false" :title="idTxt" :visible.sync="divisionVisible" v-if="divisionVisible" width="800px" top="3vh">
      <roomDivision :id="id"></roomDivision>
      
      
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="divisionVisible = false">关闭</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import pagination from '@/components/pagination'
import roomDivision from './roomDivision.vue'
export default {
  data() {
    return {
      totalNum: 0,
      addVisible: false,
      divisionVisible: false,
      ruleForm: {},
      searchForm: {
        pageNo: 1,
        pageSize: 10
      },
      tableData: [],
      useOptions:[],
      countryOptions:[],
      id: '',
      idTxt:"",
      rules: {
        blurRule: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        changeRule: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        typeIcon: [{ required: true, message: "请上传图片", trigger: "change" }],
      },
    };
  },
  components:{pagination,roomDivision},
  created() {
    this.getTableList();
    this.getCountryList();
  },
  methods: {
    toDivision(data){
      this.id = data.id;
      this.idTxt = data.name;
      this.divisionVisible = true;
    },
    addGroup(){
      this.ruleForm.reqList.push({})
    },
    delGroup(n){
      this.ruleForm.reqList.splice(n,1)
    },
    addFunc(){
      this.ruleForm = {reqList:[{name:'Default'}]}
      this.addVisible = true;
    },
    toEdit(data) {
      data.reqList = data.configList;
      this.ruleForm = {...data};
      if(this.ruleForm.reqList.length===0){
        this.ruleForm.reqList.push({name:'Default'})
      }
      this.addVisible = true;
    },
    async getCountryList() {
      let res = await this.$http.countryGroupConfigCodeList({pageNo:1,pageSize:1000});
      if (res) {
        this.countryOptions = res.result.data;
      }
    },
    async submitFunc() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.ruleForm.reqList = this.ruleForm.reqList.map(item=>{
            item.parentId=this.ruleForm.id
            return item
          })
          let res = await this.$http.countryGroupConfigSave({reqList:this.ruleForm.reqList});
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
            this.addVisible = false;
            this.$refs.ruleForm.resetFields();
          }
        }
      });
    },
    async getTableList(val) {
      if(val) {
        this.searchForm.pageNo = 1
      }
      let res = await this.$http.countryGroupConfigList({...this.searchForm});
      if (res) {
        this.tableData = res.data;
      }
    },
    delFunc(row,index) {
      if(!row.id) {
        this.delGroup(index)
        return
      }
      this.$confirm(`确定要删除么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.countryGroupConfigDel({id:row.id})
          if (res) {
            this.$message.success("操作成功！");
            this.addVisible = false;
            this.getTableList();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.groupItem{
  display: flex;
  gap: 10px;
}
</style>
