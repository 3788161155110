<template>
  <div>
    <div class="searchBox">
      <div class="searchLeft">
        <div>
          <el-input
              size="medium"
              clearable
              v-model="searchForm.name"
              placeholder="请输入地址"
              @keyup.enter.native="getTableList"
          ></el-input>
        </div>
        <el-button size="medium" type="primary" icon="el-icon-search" @click="getTableList">搜索</el-button>
      </div>
      <div class="searchRight">
        <el-button
          size="medium"
          type="primary"
          icon="el-icon-plus"
          @click="addFunc(1)"
          >新增</el-button
        >
        <el-button
          size="medium"
          type="warning"
          icon="el-icon-sort"
          @click="addFunc(2)"
          >批次设置</el-button
        >
        <el-button
          size="medium"
          type="primary"
          icon="el-icon-date"
          @click="addFunc(3)"
          >结束时间设置</el-button
        >
        <el-button
          size="medium"
          type="warning"
          icon="el-icon-refresh"
          @click="syncFunc"
          >同 步</el-button
        >
      </div>
    </div>
    <el-card shadow="always" class="cardBox">
      <div class="configBox">
        <p>当前批次：{{configData.indexNodw}}</p>
        <p>结束时间：{{Number(configData.openEndTime)*1000 | formatDate}}</p>
        <p>最新上传：{{configData.lastUploadTime}}</p>
        <p>上次同步：{{configData.lastSyncTime}}</p>
      </div>
    </el-card>

    <el-table border :data="tableData" height="76vh" style="width: 99%">
      <el-table-column prop="id" label="id"></el-table-column>
      <el-table-column prop="address" label="地址" width="400"></el-table-column>
      <el-table-column prop="articleCount" label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status==0">关闭</span>
          <span v-else class="success">正常</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="160">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="openCloseFunc(scope.row)">{{scope.row.status==0?'打开':'关闭'}}</el-button>
          <el-button size="small" type="danger" @click="delFunc(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
    <el-dialog  :close-on-click-modal="false" :title="formTypeOptions[formType]" :visible.sync="addVisible" width="600px">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item label="地址（回车分割）" prop="addressList" :rules="rules.blurRule" v-if="formType==1">
            <el-input
              type="textarea"
              :rows="5"
              placeholder="请输入内容"
              v-model="ruleForm.addressList">
            </el-input>
        </el-form-item>
        <el-form-item label="正在进行的批次" prop="indexNodw" :rules="rules.blurRule" v-if="formType==2">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.indexNodw"
          ></el-input>
        </el-form-item>
        <el-form-item label="结束时间" prop="openEndTime" :rules="rules.changeRule" v-if="formType==3">
          <el-date-picker
            size="medium"
            v-model="ruleForm.openEndTime"
            type="datetime"
            @change="getTableList"
            placeholder="结束时间"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFunc">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pagination from '@/components/pagination'
export default {
  data() {
    return {
      totalNum: 0,
      addVisible: false,
      ruleForm: {
        id:"",
        name: "",
        description:"",
        img:"",
        category:""
      },
      searchForm: {
        name:'',
        pageNo: 1,
        pageSize: 10
      },
      typeOptions: [
        { value: 1, text: '专题类型 01' },
        { value: 2, text: '专题类型 02' },
        { value: 3, text: '专题类型 03' }
      ],
      tableData: [],
      configData:{},
      dialogVisible: false,
      rules: {
        blurRule: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        changeRule: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        typeIcon: [{ required: true, message: "请上传图片", trigger: "change" }],
      },
      formTypeOptions:{
        '1':'新建白名单地址',
        '2':'批次设置',
        '3':'结束时间设置'
      },
      formType:null,
    };
  },
  components:{pagination},
  created() {
    this.getWhiteAddressLocal();
  },
  methods: {
    addFunc(type){
      this.formType = type;
      this.ruleForm = {};
      this.addVisible = true;
    },
    async submitFunc() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let res = null
          if (this.formType==1) {
            let ruleForm = {...this.ruleForm};
            ruleForm.indexNodw = this.configData.indexNodw;
            res = await this.$http.whiteAddressSave({...ruleForm});
          } else {
            res = await this.$http.whiteAddressConfigLocal({...this.ruleForm});
          }
          if (res) {
            this.$message.success("修改成功！");
            this.getWhiteAddressLocal();
            this.addVisible = false;
            this.$refs.ruleForm.resetFields();
          }
        }
      });
    },
    async getTableList(val) {
      if(val) {
        this.searchForm.pageNo = 1
      }
      this.searchForm.indexNodw = this.configData.indexNodw;
      let res = await this.$http.whiteAddressList({...this.searchForm});
      if (res) {
        this.tableData = res.result.data;
        this.totalNum = res.result.totalCount;
      }
    },
    async getWhiteAddressLocal() {
      let res = await this.$http.whiteAddressLocal();
      if (res) {
        this.configData = res.data;
        this.getTableList();
      }
    },
    openCloseFunc(row) {
      this.$confirm(`确定要${row.status==0?'打开':'关闭'}么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = null
          if(row.status==0){
            res = await this.$http.whiteAddressOpen([row.id])
          } else {
            res = await this.$http.whiteAddressClose([row.id])
          }
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
          }
        })
        .catch(() => {});
    },
    syncFunc() {
      this.$confirm(`确定要同步么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.whiteAddressSync()
          if (res) {
            this.$message.success("操作成功！");
            this.getWhiteAddressLocal();
          }
        })
        .catch(() => {});
    },
    delFunc(row) {
      this.$confirm(`删除后不可恢复，确定要删除么？`, "提示", {
        confirmButtonText: "确定",
      cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
        let res = await this.$http.whiteAddressDelete({id:row.id})
        if (res) {
          this.$message.success("操作成功！");
          this.getTableList();
        }
      })
      .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.cardBox{
    margin-bottom: 20px;
}
.configBox{
    display: flex;
    p{
        flex: 1;
        font-weight: bold;
    }
}
</style>

