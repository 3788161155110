<template>
    <div>
      <div class="searchBox">
        <div class="searchLeft">
        </div>
        <div class="searchRight">
          <el-button
            size="medium"
            type="primary"
            icon="el-icon-plus"
            @click="addFunc"
            >添加配置</el-button
          >
          <el-button
            size="medium"
            type="warning"
            icon="el-icon-edit"
            @click="editIntervalFunc"
            >编辑区间</el-button
          >
        </div>
      </div>
  
      <el-table border :data="tableData" height="76vh" style="width: 99%">
        <el-table-column prop="id" label="排序"></el-table-column>
        <el-table-column prop="userName" label="区间">
            <template slot-scope="scope">
                <span>[{{scope.row.intervalStart}} - {{scope.row.intervalEnd}}]</span>
            </template>
        </el-table-column>
        <el-table-column label="奖励" width="80">
          <template slot-scope="scope">
            <img :src="scope.row.reward[0].icon" alt="" style="width: 50px;height: 50px;" v-if="scope.row.reward&&scope.row.reward.length>0&&scope.row.type!==2">
            <img src="@/assets/images/token_talk.webp" alt="" style="width: 50px;height: 50px;" v-if="scope.row.type==2">
          </template>
        </el-table-column>
        <el-table-column prop="img" label="奖励类型">
          <template slot-scope="scope">
            <span>{{scope.row.type==0?'进场特效':scope.row.type==1?'头像':'talk'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="giftId" label="数量">
          <template slot-scope="scope">
            <span v-if="scope.row.reward&&scope.row.reward.length>0">{{scope.row.reward[0].amount}}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="160px"
          label="操作">
          <template slot-scope="scope">
            <el-button size="small" type="primary" @click="toEdit(scope.row)">编辑</el-button>
            <el-button size="small" type="danger" @click="delIntervalFunc(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
      <el-dialog  :close-on-click-modal="false" title="编辑区间" :visible.sync="editVisible" width="700px">
        <el-form
            :model="intervalForm"
          :rules="rules"
          ref="intervalForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <div class="configBox" v-for="(item,index) in intervalForm.config" :key="index">
            <p style="width: 20px;line-height: 40px;margin-top: -20px;">{{item.id||index+1}}</p>
            <el-form-item label="" label-width="0" :prop="'config.' + index + '.intervalStart'" :rules="rules.blurRule">
              <el-input v-model.number="item.intervalStart" placeholder="开始区间" disabled></el-input>
            </el-form-item>
            <el-form-item label="" label-width="0" :prop="'config.' + index + '.intervalEnd'" :rules="rules.blurRule">
              <el-input v-model.number="item.intervalEnd" placeholder="结束区间" @input="changeIntervalEnd(index)"></el-input>
            </el-form-item>
            <el-form-item label="" label-width="0">
              <el-button type="danger" icon="el-icon-delete" style="margin-bottom:0px" circle @click="delInterval(index)" v-if="!item.id"></el-button>
            </el-form-item>
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitIntervalFunc">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog  :close-on-click-modal="false" title="添加配置" :visible.sync="addVisible" width="700px" top="3vh">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="add-demo-ruleForm"
        >
          <h3 class="mrgB">区间配置</h3>
          <el-form-item label="开始区间" prop="intervalStart" :rules="rules.blurRule">
            <el-input v-model.number="ruleForm.intervalStart" placeholder="开始区间" disabled></el-input>
          </el-form-item>
          <el-form-item label="结束区间" prop="intervalEnd" :rules="rules.blurRule">
            <el-input v-model.number="ruleForm.intervalEnd" placeholder="结束区间"></el-input>
          </el-form-item>
          <h3>奖励配置</h3>
          <el-form-item label="奖励类型" prop="reward.rewardName" :rules="rules.changeRule">
            <el-select
              size="medium"
              clearable
              v-model="ruleForm.reward.rewardName"
              placeholder="奖励类型"
              @change="changeType"
            >
              <el-option
                v-for="(item,key) in typeOptions"
                :key="key"
                :label="item"
                :value="key">
              </el-option>
            </el-select>
          </el-form-item>
          <div v-if="ruleForm.reward.rewardName!='talk'">
            <div class="flex">
              <el-form-item label="选择装饰" v-if="ruleForm.reward.rewardName!='talk'" prop="reward" :rules="rules.changeRule">
                <el-select v-model="ruleForm.prop" placeholder="请选择">
                  <el-option
                    v-for="(item,index) in propsList"
                    :key="index"
                    :label="item.name"
                    :value="JSON.stringify(item)">
                    <span style="float: left">
                      <img :src="item.icon" alt="" style="width: 40px;height: 40px;">
                    </span>
                    <p style="float: right; color: #8492a6; font-size: 13px">{{ item.name }} <span style="color: #8492a6; font-size: 13px">({{item.type==0?'进场特效':'头像框'}})</span></p>
                  </el-option>
                </el-select>
              </el-form-item>
              <img :src="JSON.parse(ruleForm.prop).icon" alt="" style="width: 40px;height: 40px;" v-if="ruleForm.prop">
            </div>
          </div>
          <el-form-item :label="ruleForm.reward.rewardName=='talk'?'数量':'天数'" prop="reward.amount" :rules="rules.blurRule">
            <el-input
              v-model.number="ruleForm.reward.amount"
              type="number"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <h3>奖池配置</h3>
          <div class="giftConfigBox">
            <div class="giftConfigHeaderBox">
              <p class="id">排序</p>
              <p>积分</p>
              <p>库存</p>
            </div>
            <div class="giftConfigContentBox">
              <div v-for="(item,index) in ruleForm.stock" :key="index" class="configBox">
                <p class="id">{{index+1}}</p>
                <div class="item">
                  <el-form-item label="" label-width="0" :prop="'stock.' + index + '.points'" :rules="rules.blurRule">
                    <el-input
                      v-model.number="item.points"
                      style="width:100% !important"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="item">
                  <el-form-item label="" label-width="0" :prop="'stock.' + index + '.nowStock'" :rules="rules.blurRule">
                  <el-input
                    v-model.number="item.nowStock"
                    style="width:100% !important"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitFunc">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import uploadImg from '@/components/uploadImg'
  import pagination from '@/components/pagination'
  export default {
    data() {
      return {
        totalNum: 0,
        addVisible: false,
        editVisible:false,
        typeOptions:{
          'avatar_props':'装饰',
          'talk':'talk币'

        },
        ruleForm: {
          reward:{},
          stock:[]
        },
        intervalForm:{
          config:[]
        },
        searchForm: {
          name:null,
          sortOrder:null,
          sortField:null,
          pageNo: 1,
          pageSize: 10
        },
        tableData: [],
        rules: {
          blurRule: [
            { required: true, message: "请输入", trigger: "blur" },
          ],
          changeRule: [
            { required: true, message: "请选择", trigger: "change" },
          ],
          typeIcon: [{ required: true, message: "请上传图片", trigger: "change" }],
        },
        propsList:[]
      };
    },
    components:{uploadImg,pagination},
    created() {
      this.getTableList();
      this.getPropsList();
    },
    methods: {
      changeType(){
        this.$set(this.ruleForm,'prop',null)
      },
      changeIntervalEnd(index){
        if(index+1<this.intervalForm.config.length){
          this.intervalForm.config[index+1].intervalStart = this.intervalForm.config[index].intervalEnd+1
        }
      },
      addFunc(){
        let ruleForm = {}
        ruleForm={intervalStart:this.tableData[this.tableData.length-1].intervalEnd+1};
        ruleForm.stock=[{},{},{},{},{},{}]
        ruleForm.reward = {}
        this.ruleForm = {...ruleForm}
        this.addVisible = true;
      },
      toEdit(data) {
        //编辑
        this.ruleForm = {...data}
        this.ruleForm.reward = {...data.reward[0]}
        this.ruleForm.prop = JSON.stringify(this.propsList.find(x=>x.id==this.ruleForm.reward.id))
        this.addVisible = true;
      },
      editIntervalFunc(){
        this.intervalForm={config:[...this.tableData]};
        this.editVisible = true;
      },
      submitIntervalFunc(){
        this.$refs.intervalForm.validate(async (valid) => {
          if (valid) {
            let res = await this.$http.taskDialConfigSave([...this.intervalForm.config])
            if(res){
              this.$message.success("操作成功！");
              this.getTableList();
              this.editVisible = false;
              this.$refs.intervalForm.resetFields();
            }
          }
        })
      },
      submitFunc(){
        this.$refs.ruleForm.validate(async (valid) => {
          if (valid) {
            let ruleForm = {...this.ruleForm}
            ruleForm.reward = [{...ruleForm.reward,...JSON.parse(ruleForm.prop)}]
            ruleForm.type = ruleForm.reward[0].rewardName=='talk'?2:ruleForm.reward[0].type
            let res = await this.$http.taskDialConfigSave([ruleForm])
            if(res){
              this.$message.success("操作成功！");
              this.getTableList();
              this.addVisible = false;
              this.$refs.ruleForm.resetFields();
            }
          }
        })
      },
      async delIntervalFunc(row){
        this.$confirm(`确定要删除么？`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            let res = await this.$http.taskDialConfigDel({id:row.id})
            if (res) {
              this.$message.success("操作成功！");
              this.getTableList();
            }
          })
          .catch(() => {});
      },  
      
      async getTableList(val) {
        if(val) {
          this.searchForm.pageNo = 1
        }
        let res = await this.$http.taskDialConfigList();
        if (res) {
          this.tableData = res.data;
        }
      },
      async getPropsList(){
        let res = await this.$http.userPropsConfigList({pageNo:1,pageSize:1000,status:1});
        if (res) {
          this.propsList = res.result.data; 
        }
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .configBox{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .flex{
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }
  h3{
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .mrgB{
    margin-top: 0px;
  }
  .giftConfigBox{
    width: 80%;
  }
  .giftConfigHeaderBox{
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
    .id{
      width: 50px;
      flex: none;
    }
    p{
      flex: 1;
      text-align: center;
    }
  }
  .giftConfigContentBox{
  .configBox{
    display: flex;
    align-items: center;
    .item{
      flex: 1;
      padding: 0 10px;
    }
    .id{
      width: 50px;
      flex: none;
      margin-bottom: 22px;
      text-align: center;
    }
    .flex{
      width: 200px;
      flex: none;
    }
    .el-input{
      width: 100% !important;
    }
  }
}
.add-demo-ruleForm{
  height: 75vh;
  overflow-y: auto;
}
  </style>
  