import { render, staticRenderFns } from "./treasureGift.vue?vue&type=template&id=92e8c730&scoped=true"
import script from "./treasureGift.vue?vue&type=script&lang=js"
export * from "./treasureGift.vue?vue&type=script&lang=js"
import style0 from "./treasureGift.vue?vue&type=style&index=0&id=92e8c730&prod&lang=scss&scoped=true"
import style1 from "./treasureGift.vue?vue&type=style&index=1&id=92e8c730&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92e8c730",
  null
  
)

export default component.exports