<template>
  <div>
   <div class="searchBox">
    <div class="searchLeft">
      <el-input size="medium" clearable v-model="searchForm.name" placeholder="请输入名称"  @keyup.enter.native="getTableList"></el-input>
      <el-input size="medium" clearable v-model="searchForm.url" placeholder="请输入地址"  @keyup.enter.native="getTableList"></el-input>
      <el-button size="medium" type="primary" icon="el-icon-search" @click="getTableList">搜索</el-button>
    </div>
    <div class="searchRight">
      <el-button size="medium" type="primary" icon="el-icon-plus" @click="addFunc">增加</el-button>
    </div>
   </div>
   <el-table
    border
    :data="tableData"
    style="width: 100%">
    <el-table-column
      prop="id"
      label="id">
    </el-table-column>
    <el-table-column
      prop="name"
      label="名称">
    </el-table-column>
    <el-table-column
      prop="url"
      label="地址">
    </el-table-column>
    <el-table-column
      prop="way"
      label="操作">
      <template slot-scope="scope">
        <el-button size="mini" type="primary" @click="editType(scope.row)">修改</el-button>
        <el-button size="mini" type="danger" @click="deleteType(scope.row)">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
  <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
    <el-dialog  :close-on-click-modal="false"
    :title="!ruleForm.id?'添加接口':'修改接口'"
    :visible.sync="dialogVisible"
    width="440px">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="名称" prop="name" :rules="rules.blurRule">
            <el-input placeholder="请输入名称" v-model="ruleForm.name" style="width:240px"></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="url" :rules="rules.blurRule">
            <el-input placeholder="请输入地址" v-model="ruleForm.url" style="width:240px"></el-input>
        </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
    </span>
    </el-dialog>
  </div>
</template>

<script>
import pagination from '@/components/pagination'
export default {
  data() {
    return {
      totalNum:0,
      searchForm:{
        pageNo: 1,
        pageSize: 10
      },
      tableData: [],
      dialogVisible:false,
      ruleForm:{},
      rules:{
          blurRule:[
              { required: true, message: '请输入', trigger: 'blur' }
          ],
      }
    };
  },
  components:{pagination},
  created() {
    this.getTableList()
  },
  methods: {
    submitForm(formName) {
        this.$refs[formName].validate(async (valid) => {
            if (valid) {
                let res = null
                if(this.ruleForm.id) {
                  res = await this.$http.resourcesUpdate({...this.ruleForm});
                }else {
                  res = await this.$http.resourcesAdd({...this.ruleForm});
                }
                if (res) {
                  this.$message.success('操作成功！');
                  this.dialogVisible=false;
                  this.getTableList()
                }
            } else {
            console.log('error submit!!');
            return false;
            }
        });
    },
    deleteType(row){
        this.$confirm(`确定要删除 ${row.name} 接口吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          let res = await this.$http.resourcesDelete([row.id]);
          if (res) {
            this.$message.success('操作成功！');
            this.getTableList()
          }
        }).catch(() => {
                
        });
    },
    editType(row){
        this.ruleForm={...row};
        this.dialogVisible=true
    },

    addFunc(){
        this.ruleForm={};
        this.dialogVisible=true;
    },
    async getTableList(){
       let res = await this.$http.resourcesList({...this.searchForm});
        if (res) {
        this.tableData = res.result.data;
        this.totalNum = res.result.totalCount;
      }
    }
  }
};
</script>

<style scoped>


</style>
